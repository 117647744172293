// eslint-disable no-use-before-define
// eslint-disable react/prop-types
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import { PieChart as PieChartIcon } from 'react-feather';
import NavItem from './NavItem';
import UsersSubItems from './UsersSubItems';
import EngineeringSubItem from './EngineeringSubItem';
import ReviewerSubItem from './ReviewerSubItem';
import FireSubItem from './FireSubItem';
import MpdoSubItem from './MpdoSubItem';
import AdministratorSubItem from './AdministratorSubItem';
import TreasurySubItem from './TreasurySubItem';
import RequirementsReviewerSubItem from './RequirementsReviewerSubItem';
import StructuralReviewerSubItem from './StructuralReviewerSubItem';
import ElectricalReviewerSubitem from './ElectricalReviewerSubitem';
import ArcheticturalReviewerSubitem from './ArcheticturalReviewerSubitem';
import PlumbingReviewerSubitem from './PlumbingReviewerSubitem';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        enabled={item.enabled}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        enabled={item.enabled}
      />
    );
  }

  return acc;
}

const imageLocation = process.env.REACT_APP_IMAGE;

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    transition: 'width 0.3s ease'
  },
  desktopDrawerClose: {
    width: 0,
    top: 64,
    height: 'calc(100% - 64px)',

    transition: 'width 0.3s ease'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, isDesktopHideSidebar, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);
  const userlink = UsersSubItems();
  const engineeringlink = EngineeringSubItem();
  const reviewerlink = ReviewerSubItem();
  const firelink = FireSubItem();
  const mpdolink = MpdoSubItem();
  const adminLink = AdministratorSubItem();
  const treasuryLink = TreasurySubItem();
  const requirementsReviewierLink = RequirementsReviewerSubItem();
  const srs = StructuralReviewerSubItem();
  const ers = ElectricalReviewerSubitem();
  const ars = ArcheticturalReviewerSubitem();
  const prs = PlumbingReviewerSubitem();

  const navConfig = [
    {
      subheader: '',
      owner: user.roles[0].name,
      items: [
        {
          title: 'Dashboard',
          icon: PieChartIcon,
          href: '/app/reports/dashboard',
          enabled: true
        }
      ]
    },
    userlink,
    engineeringlink,
    reviewerlink,
    firelink,
    mpdolink,
    adminLink,
    treasuryLink,
    requirementsReviewierLink,
    srs,
    ers,
    ars,
    prs
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account" style={{ textDecoration: 'none' }}>
              <Avatar
                alt={user.firstname}
                className={classes.avatar}
                src={`${imageLocation}avatar/${user.avatar}`}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstname} ${user.lastname}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config, index) => (
            <List
              style={{
                display: config.owner === user.roles[0].name ? 'block' : 'none'
              }}
              key={index}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Hidden xsUp xsDown>
          <Box p={1}>
            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
              <Typography variant="h6" color="textSecondary" align="center">
                To register your property? Click{' '}
              </Typography>

              <Box mt={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/app/property/register"
                  fullWidth
                >
                  {' '}
                  Register{' '}
                </Button>
              </Box>
            </Box>
          </Box>
        </Hidden>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: isDesktopHideSidebar
              ? classes.desktopDrawerClose
              : classes.desktopDrawer
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
