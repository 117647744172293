// import DoneAll from '@material-ui/icons/DoneAll';
import DvrIcon from '@material-ui/icons/Dvr';
import ListAltIcon from '@material-ui/icons/ShortText';

const ReviewerSubItem = () => {
  const links = {
    subheader: 'Reviewer Submenu',
    owner: 'reviewer',
    items: [
      {
        title: 'For Engineering',
        icon: DvrIcon,
        href: '/reviewer/reviewed/request/for-engineering',
        enabled: true
      },

      {
        title: 'Processed Permits',
        icon: ListAltIcon,
        href: '/reviewer/reviewed/request/permit/approved-list',
        enabled: true
      },
      {
        title: 'Processed Permits For Occupancy',
        icon: ListAltIcon,
        href: '/reviewer/reviewed/request/occupancy/permit/approved-list',
        enabled: true
      },

      {
        title: 'Processed Permits For Electrical',
        icon: ListAltIcon,
        href: '/reviewer/electrical-permit/request/completed-list',
        enabled: true
      }
    ]
  };

  return links;
};

export default ReviewerSubItem;
