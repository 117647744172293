/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import HomeView from './views/pages/HomeView';
import MainLayout from './layouts/MainLayout';
import EngineeringGuard from './components/guard/EngineeringGuard';
import ReviewerGuard from './components/guard/ReviewerGuard';
import FireGuard from './components/guard/FireGuard';
import MpdoGuard from './components/guard/MpdoGuard';
import AdministratorGuard from './components/guard/AdministratorGuard';
import TreasuryGuard from './components/guard/TreasuryGuard';
import RequirementsReviewerGuard from './components/guard/RequirementsReviewerGuard';
import StructuralReviewerGuard from './components/guard/StructuralReviewerGuard';
import ArchitecturalReviewerGuard from './components/guard/ArchitecturalReviewerGuard';
import PlumbingReviewerGuard from './components/guard/PlumbingReviewerGuard';
import ElectricalReviewerGuard from './components/guard/ElectricalReviewerGuard';
import LineAndGradeGuard from './components/guard/LineAndGradeGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/register/sample-page',
    component: () => <h1> Sample Page 2nd build </h1>
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/recover/password',
    component: lazy(() => import('src/views/auth/RecoverPasswordView'))
  },

  {
    exact: true,
    guard: GuestGuard,
    path: '/recover/password/reset-form/:email/:token',
    component: lazy(() =>
      import('src/views/auth/RecoverPasswordView/NewPasswordView/')
    )
  },

  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },

  // users route
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: () => <Redirect to="/app/reports/dashboard" />
      },

      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/users/request/building-permit',
        component: lazy(() => import('src/views/users/request/building-permit'))
      },

      // new building permit request - stepper
      {
        exact: true,
        path: '/app/building-permit/new',
        component: lazy(() =>
          import('src/views/users/request/building-permit-stepper')
        )
      },

      {
        exact: true,
        path: '/app/users/request/permit-list',
        component: lazy(() => import('src/views/users/request/PermitList'))
      },
      {
        exact: true,
        path: '/app/users/request/permit-forpayment',
        component: lazy(() => import('src/views/users/forpayment'))
      },
      {
        exact: true,
        path: '/app/users/request/permit-foroccupancy',
        component: lazy(() => import('src/views/users/foroccupancy'))
      },
      {
        exact: true,
        path: '/app/users/request/permit-foroccupancy/request/:application_no',
        component: lazy(() =>
          import('src/views/users/foroccupancy/certificate/Request')
        )
      },
      {
        exact: true,
        path: '/app/users/request/permit-foroccupancy/list',
        component: lazy(() =>
          import('src/views/users/foroccupancy/certificate/RequestList')
        )
      },
      {
        exact: true,
        path: '/app/users/request/permit/requirements/:application_no',
        component: lazy(() => import('src/views/users/requirements'))
      },
      // {
      //   exact: true,
      //   path: '/app/reports/dashboard',
      //   component: lazy(() => import('src/views/users'))
      // },

      {
        exact: true,
        path: '/app/building-permit/draft',
        component: lazy(() => import('src/views/users'))
      },

      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/users/dashboard'))
      },

      {
        exact: true,
        path: '/app/users/request/permit/assessment/:application_no',
        component: lazy(() =>
          import('src/views/users/request/PermitAssessment')
        )
      },

      {
        exact: true,
        path: '/app/users/request/permit/update/:application_no',
        component: lazy(() => import('src/views/users/request/Edit'))
      },

      {
        exact: true,
        path:
          '/app/users/request/occupancy/permit/assessment/:application_no/:occupancy_id',
        component: lazy(() =>
          import('src/views/users/assessment/PermitForOccupancyAssessment')
        )
      },

      {
        exact: true,
        path:
          '/app/users/request/occupancy/permit/requirements/:application_no/:occupancy_id',
        component: lazy(() =>
          import('src/views/users/requirements/PermitForOccupancyRequirements')
        )
      },

      {
        exact: true,
        path: '/app/users/building/permit/electrical/request/:application_no',
        component: lazy(() =>
          import('src/views/users/electical-permit/request-form/index')
        )
      },
      {
        exact: true,
        path: '/app/users/electrical-permit/request-list',
        component: lazy(() => import('src/views/users/electical-permit'))
      },

      {
        exact: true,
        path: '/app/users/electrical-permit/printable-from/:electrical_no',
        component: lazy(() => import('src/views/pages/PrintableElectricalForm'))
      },
      {
        exact: true,
        path:
          '/app/users/electrical-permit/edit-from/:application_no/:electrical_no',
        component: lazy(() => import('src/views/users/electical-permit/edit'))
      },

      {
        exact: true,
        path: '/app/users/electrical-permit/online/payment/payment-callback',
        component: lazy(() =>
          import('src/views/users/request/online-payment/SuccessPayment')
        )
      },

      {
        exact: true,
        path: '/app/users/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
        // component: lazy(() => import('src/views/users/inquiries'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  // engineering route
  {
    path: '/engineering',
    guard: EngineeringGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/engineering',
        component: () => <Redirect to="/engineering/reports/dashboard" />
      },
      {
        exact: true,
        path: '/engineering/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/engineering/reports/dashboard',
        component: lazy(() => import('src/views/engineering'))
      },

      {
        exact: true,
        path: '/engineering/request/permit/requirements/:application_no',
        component: lazy(() => import('src/views/engineering/requirements'))
      },
      {
        exact: true,
        path: '/engineering/reviewed/request/permit/approved-list',
        component: lazy(() => import('src/views/engineering/approved-list'))
      },
      {
        exact: true,
        path: '/engineering/request/permit/assessment-list/:application_no',
        component: lazy(() => import('src/views/engineering/assessment'))
      },
      {
        exact: true,
        path: '/engineering/reviewed/request/permit/for-print',
        component: lazy(() => import('src/views/engineering/permits-forprint'))
      },
      {
        exact: true,
        path:
          '/engineering/request/occupancy/permit/requirements/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/engineering/requirements/PermitForOccupancyRequirements'
          )
        )
      },
      {
        exact: true,
        path:
          '/engineering/request/occupancy/permit/assessment/:application_no/:occupancy_id',
        component: lazy(() =>
          import('src/views/engineering/assessment/OccupancyPermit')
        )
      },
      {
        exact: true,
        path:
          '/engineering/request/occupancy/permit/assessment/:application_no/:occupancy_id',
        component: lazy(() =>
          import('src/views/engineering/assessment/OccupancyPermit')
        )
      },
      {
        exact: true,
        path:
          '/engineering/request/occupancy/permit/details/:application_no/:occupancy_id',
        component: lazy(() =>
          import('src/views/engineering/for-review/PermitForOccupancyDetails')
        )
      },

      {
        exact: true,
        path: '/engineering/reviewed/request/occupancy/permit/for-print',
        component: lazy(() =>
          import('src/views/engineering/permits-forprint/OccupancyPermit')
        )
      },

      {
        exact: true,
        path: '/engineering/reviewed/request/permit/electrical/list',
        component: lazy(() => import('src/views/engineering/electrical'))
      },

      {
        exact: true,
        path: '/engineering/electrical-permit/printable-from/:electrical_no',
        component: lazy(() => import('src/views/pages/PrintableElectricalForm'))
      },

      {
        exact: true,
        path: '/engineering/electrical-permit/details/:electrical_no',
        component: lazy(() =>
          import('src/views/engineering/electrical/Details')
        )
      },

      {
        exact: true,
        path: '/engineering/electrical-permit/completed',
        component: lazy(() =>
          import('src/views/engineering/electrical/Completed')
        )
      },

      {
        exact: true,
        path: '/engineering/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/reviewer',
    guard: ReviewerGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/reviewer',
        component: () => <Redirect to="/reviewer/reports/dashboard" />
      },
      {
        exact: true,
        path: '/reviewer/reports/dashboard',
        component: lazy(() => import('src/views/reviewer'))
      },
      {
        exact: true,
        path: '/reviewer/request/permit/requirements/:application_no',
        component: lazy(() => import('src/views/reviewer/requirements'))
      },
      {
        exact: true,
        path: '/reviewer/reviewed/request/permit/approved-list',
        component: lazy(() => import('src/views/reviewer/reviewed'))
      },

      {
        exact: true,
        path: '/reviewer/reviewed/request/for-engineering',
        component: lazy(() => import('src/views/reviewer/for-engineering'))
      },
      {
        exact: true,
        path: '/reviewer/reviewed/request/occupancy/permit/approved-list',
        component: lazy(() =>
          import('src/views/reviewer/reviewed/PermitForOccupancyReviewed')
        )
      },

      {
        exact: true,
        path: '/reviewer/electrical-permit/printable-from/:electrical_no',
        component: lazy(() => import('src/views/pages/PrintableElectricalForm'))
      },

      {
        exact: true,
        path: '/reviewer/electrical-permit/details/:electrical_no',
        component: lazy(() =>
          import('src/views/reviewer/electical-permit/request-list/Details')
        )
      },

      {
        exact: true,
        path: '/reviewer/electrical-permit/:type',
        component: lazy(() => import('src/views/reviewer/electical-permit'))
      },

      {
        exact: true,
        path: '/reviewer/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },

      {
        exact: true,
        path: '/reviewer/request/permit/assessment/:application_no',
        component: lazy(() => import('src/views/reviewer/assessment'))
      },

      {
        exact: true,
        path:
          '/reviewer/request/occupancy/permit/assessment/:application_no/:occupancy_id',
        component: lazy(() =>
          import('src/views/reviewer/assessment/OccupancyAssessment')
        )
      },
      {
        exact: true,
        path:
          '/reviewer/request/occupancy/permit/requirements/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/reviewer/requirements/PermitForOccupancyRequirements'
          )
        )
      },
      {
        exact: true,
        path:
          '/reviewer/request/occupancy/permit/details/:application_no/:occupancy_id',
        component: lazy(() =>
          import('src/views/reviewer/for-review/PermitForOccupancyDetails')
        )
      },

      {
        exact: true,
        path: '/reviewer/electrical-permit/request/completed-list',
        component: lazy(() =>
          import('src/views/reviewer/electical-permit/request-list/Completed')
        )
      },

      {
        exact: true,
        path: '/reviewer/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '/requirements-reviewer',
    guard: RequirementsReviewerGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/requirements-reviewer',
        component: () => (
          <Redirect to="/requirements-reviewer/reports/dashboard" />
        )
      },
      {
        exact: true,
        path: '/requirements-reviewer/reports/dashboard',
        component: lazy(() => import('src/views/requirements-reviewer'))
      },
      {
        exact: true,
        path:
          '/requirements-reviewer/request/permit/requirements/:application_no',
        component: lazy(() =>
          import('src/views/requirements-reviewer/requirements')
        )
      },
      {
        exact: true,
        path: '/requirements-reviewer/reviewed/request/permit/approved-list',
        component: lazy(() =>
          import('src/views/requirements-reviewer/reviewed')
        )
      },

      {
        exact: true,
        path: '/requirements-reviewer/reviewed/request/for-engineering',
        component: lazy(() =>
          import('src/views/requirements-reviewer/for-engineering')
        )
      },
      {
        exact: true,
        path:
          '/requirements-reviewer/reviewed/request/occupancy/permit/approved-list',
        component: lazy(() =>
          import(
            'src/views/requirements-reviewer/reviewed/PermitForOccupancyReviewed'
          )
        )
      },

      {
        exact: true,
        path:
          '/requirements-reviewer/electrical-permit/printable-from/:electrical_no',
        component: lazy(() => import('src/views/pages/PrintableElectricalForm'))
      },

      {
        exact: true,
        path: '/requirements-reviewer/electrical-permit/details/:electrical_no',
        component: lazy(() =>
          import(
            'src/views/requirements-reviewer/electical-permit/request-list/Details'
          )
        )
      },

      {
        exact: true,
        path: '/requirements-reviewer/electrical-permit/:type',
        component: lazy(() =>
          import('src/views/requirements-reviewer/electical-permit')
        )
      },

      {
        exact: true,
        path: '/requirements-reviewer/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },

      {
        exact: true,
        path:
          '/requirements-reviewer/request/permit/assessment/:application_no',
        component: lazy(() =>
          import('src/views/requirements-reviewer/assessment')
        )
      },

      {
        exact: true,
        path:
          '/requirements-reviewer/request/occupancy/permit/assessment/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/requirements-reviewer/assessment/OccupancyAssessment'
          )
        )
      },
      {
        exact: true,
        path:
          '/requirements-reviewer/request/occupancy/permit/requirements/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/requirements-reviewer/requirements/PermitForOccupancyRequirements'
          )
        )
      },
      {
        exact: true,
        path:
          '/requirements-reviewer/request/occupancy/permit/details/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/requirements-reviewer/for-review/PermitForOccupancyDetails'
          )
        )
      },

      {
        exact: true,
        path: '/requirements-reviewer/electrical-permit/request/completed-list',
        component: lazy(() =>
          import(
            'src/views/requirements-reviewer/electical-permit/request-list/Completed'
          )
        )
      },

      {
        exact: true,
        path: '/requirements-reviewer/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '/fire',
    guard: FireGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/fire',
        component: () => <Redirect to="/fire/reports/dashboard" />
      },
      {
        exact: true,
        path: '/fire/reports/dashboard',
        component: lazy(() => import('src/views/fire'))
      },
      {
        exact: true,
        path: '/fire/request/permit/assessment/:application_no',
        component: lazy(() => import('src/views/fire/assessment'))
      },
      {
        exact: true,
        path: '/fire/request/permit/requirements/:application_no',
        component: lazy(() => import('src/views/fire/requirements'))
      },
      {
        exact: true,
        path: '/fire/approved/request/permit/approved-list',
        component: lazy(() => import('src/views/fire/reviewed'))
      },
      {
        exact: true,
        path:
          '/fire/request/occupancy/permit/assessment/:application_no/:occupancy_id',
        component: lazy(() =>
          import('src/views/fire/assessment/OccupancyAssessment')
        )
      },
      {
        exact: true,
        path:
          '/fire/request/occupancy/permit/requirements/:application_no/:occupancy_id',
        component: lazy(() =>
          import('src/views/fire/requirements/PermitForOccupancyRequirements')
        )
      },
      {
        exact: true,
        path:
          '/fire/request/occupancy/permit/details/:application_no/:occupancy_id',
        component: lazy(() =>
          import('src/views/fire/for-review/PermitForOccupancyDetails')
        )
      },
      {
        exact: true,
        path: '/fire/reviewed/request/occupancy/permit/approved-list',
        component: lazy(() =>
          import('src/views/fire/reviewed/PermitForOccupancyReviewed')
        )
      },

      {
        exact: true,
        path: '/fire/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/mpdo',
    guard: MpdoGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/mpdo',
        component: () => <Redirect to="/mpdo/reports/dashboard" />
      },
      {
        exact: true,
        path: '/mpdo/reports/dashboard',
        component: lazy(() => import('src/views/mpdo'))
      },
      {
        exact: true,
        path: '/mpdo/request/permit/assessment/:application_no',
        component: lazy(() => import('src/views/mpdo/assessment'))
      },
      {
        exact: true,
        path: '/mpdo/request/permit/requirements/:application_no',
        component: lazy(() => import('src/views/mpdo/requirements'))
      },
      {
        exact: true,
        path: '/mpdo/approved/request/permit/approved-list',
        component: lazy(() => import('src/views/mpdo/reviewed'))
      },
      {
        exact: true,
        path: '/mpdo/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/administrator',
    guard: AdministratorGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/administrator',
        component: () => <Redirect to="/administrator/reports/dashboard" />
      },
      {
        exact: true,
        path: '/administrator/reports/dashboard',
        component: lazy(() => import('src/views/administrator'))
      },

      {
        exact: true,
        path: '/administrator/users/roles',
        component: lazy(() => import('src/views/administrator/roles'))
      },

      {
        exact: true,
        path: '/administrator/users',
        component: lazy(() => import('src/views/administrator/users'))
      },
      {
        exact: true,
        path: '/administrator/building/permit/municipality',
        component: lazy(() => import('src/views/administrator/municipality'))
      },
      {
        exact: true,
        path: '/administrator/building/permit/barangay',
        component: lazy(() => import('src/views/administrator/barangay'))
      },
      {
        exact: true,
        path: '/administrator/building/permit/type',
        component: lazy(() =>
          import('src/views/administrator/application-type')
        )
      },

      {
        exact: true,
        path: '/administrator/building/permit/status',
        component: lazy(() =>
          import('src/views/administrator/application-status')
        )
      },

      {
        exact: true,
        path: '/administrator/building/permit/fees-type',
        component: lazy(() =>
          import('src/views/administrator/application-feestype')
        )
      },

      {
        exact: true,
        path: '/administrator/building/permit/scope',
        component: lazy(() => import('src/views/administrator/scopeof-work'))
      },

      {
        exact: true,
        path: '/administrator/building/permit/ownership',
        component: lazy(() =>
          import('src/views/administrator/formof-ownership')
        )
      },

      {
        exact: true,
        path: '/administrator/building/permit/group-use',
        component: lazy(() => import('src/views/administrator/group-use'))
      },

      {
        exact: true,
        path: '/administrator/building/permit/requirements/grouping',
        component: lazy(() =>
          import('src/views/administrator/requirements-group')
        )
      },

      {
        exact: true,
        path: '/administrator/building/permit/fees-account',
        component: lazy(() =>
          import('src/views/administrator/application-feesaccount')
        )
      },

      {
        exact: true,
        path: '/administrator/building/permit/occupancy-character',
        component: lazy(() =>
          import('src/views/administrator/group-use-occupancy')
        )
      },
      {
        exact: true,
        path: '/administrator/building/permit/requirements/list',
        component: lazy(() =>
          import('src/views/administrator/application-requirements-list')
        )
      },

      {
        exact: true,
        path: '/administrator/reports/payments',
        component: lazy(() =>
          import('src/views/administrator/reports/payments')
        )
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/treasury',
    guard: TreasuryGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/treasury',
        component: () => <Redirect to="/treasury/reports/dashboard" />
      },
      {
        exact: true,
        path: '/treasury/reports/dashboard',
        component: lazy(() => import('src/views/treasury'))
      },
      {
        exact: true,
        path: '/treasury/approved/request/permit/paid-list',
        component: lazy(() => import('src/views/treasury/permit-paid'))
      },

      {
        exact: true,
        path: '/treasury/approved/request/occupancy/permit/paid-list',
        component: lazy(() =>
          import('src/views/treasury/permit-paid/OccupancyPermitPaid')
        )
      },

      {
        exact: true,
        path: '/treasury/electrical-permit/details/:electrical_no',
        component: lazy(() =>
          import('src/views/treasury/permit-forpayment/electrical/Details')
        )
      },

      {
        exact: true,
        path: '/treasury/electrical-permit/completed',
        component: lazy(() =>
          import('src/views/treasury/permit-paid/ElectricalPermit')
        )
      },

      {
        exact: true,
        path: '/treasury/electrical-permit/printable-from/:electrical_no',
        component: lazy(() => import('src/views/pages/PrintableElectricalForm'))
      },

      {
        exact: true,
        path: '/treasury/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  // strutural routes
  {
    path: '/structural-reviewer',
    guard: StructuralReviewerGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/structural-reviewer',
        component: () => (
          <Redirect to="/structural-reviewer/reports/dashboard" />
        )
      },
      {
        exact: true,
        path: '/structural-reviewer/reports/dashboard',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/structural')
        )
      },
      {
        exact: true,
        path:
          '/structural-reviewer/request/permit/requirements/:application_no',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/structural/requirements')
        )
      },
      {
        exact: true,
        path: '/structural-reviewer/reviewed/request/permit/approved-list',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/structural/reviewed')
        )
      },
      {
        exact: true,
        path:
          '/structural-reviewer/reviewed/request/occupancy/permit/approved-list',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/structural/reviewed/PermitForOccupancyReviewed'
          )
        )
      },

      {
        exact: true,
        path:
          '/structural-reviewer/electrical-permit/printable-from/:electrical_no',
        component: lazy(() => import('src/views/pages/PrintableElectricalForm'))
      },

      {
        exact: true,
        path: '/structural-reviewer/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },

      {
        exact: true,
        path:
          '/structural-reviewer/request/occupancy/permit/requirements/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/structural/requirements/PermitForOccupancyRequirements'
          )
        )
      },
      {
        exact: true,
        path:
          '/structural-reviewer/request/occupancy/permit/details/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/structural/for-review/PermitForOccupancyDetails'
          )
        )
      },

      {
        exact: true,
        path: '/structural-reviewer/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  // architectural routes
  {
    path: '/architectural-reviewer',
    guard: ArchitecturalReviewerGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/architectural-reviewer',
        component: () => (
          <Redirect to="/architectural-reviewer/reports/dashboard" />
        )
      },
      {
        exact: true,
        path: '/architectural-reviewer/reports/dashboard',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/architectural')
        )
      },
      {
        exact: true,
        path:
          '/architectural-reviewer/request/permit/requirements/:application_no',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/architectural/requirements')
        )
      },
      {
        exact: true,
        path: '/architectural-reviewer/reviewed/request/permit/approved-list',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/architectural/reviewed')
        )
      },
      {
        exact: true,
        path:
          '/architectural-reviewer/reviewed/request/occupancy/permit/approved-list',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/architectural/reviewed/PermitForOccupancyReviewed'
          )
        )
      },

      {
        exact: true,
        path:
          '/architectural-reviewer/electrical-permit/printable-from/:electrical_no',
        component: lazy(() => import('src/views/pages/PrintableElectricalForm'))
      },

      {
        exact: true,
        path: '/architectural-reviewer/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },

      {
        exact: true,
        path:
          '/architectural-reviewer/request/occupancy/permit/requirements/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/architectural/requirements/PermitForOccupancyRequirements'
          )
        )
      },
      {
        exact: true,
        path:
          '/architectural-reviewer/request/occupancy/permit/details/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/architectural/for-review/PermitForOccupancyDetails'
          )
        )
      },

      {
        exact: true,
        path: '/architectural-reviewer/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  // electrical routes
  {
    path: '/electrical-reviewer',
    guard: ElectricalReviewerGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/electrical-reviewer',
        component: () => (
          <Redirect to="/electrical-reviewer/reports/dashboard" />
        )
      },
      {
        exact: true,
        path: '/electrical-reviewer/reports/dashboard',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/electrical')
        )
      },
      {
        exact: true,
        path:
          '/electrical-reviewer/request/permit/requirements/:application_no',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/electrical/requirements')
        )
      },
      {
        exact: true,
        path: '/electrical-reviewer/reviewed/request/permit/approved-list',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/electrical/reviewed')
        )
      },
      {
        exact: true,
        path:
          '/electrical-reviewer/reviewed/request/occupancy/permit/approved-list',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/electrical/reviewed/PermitForOccupancyReviewed'
          )
        )
      },

      {
        exact: true,
        path:
          '/electrical-reviewer/electrical-permit/printable-from/:electrical_no',
        component: lazy(() => import('src/views/pages/PrintableElectricalForm'))
      },

      {
        exact: true,
        path: '/electrical-reviewer/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },

      {
        exact: true,
        path:
          '/electrical-reviewer/request/occupancy/permit/requirements/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/electrical/requirements/PermitForOccupancyRequirements'
          )
        )
      },
      {
        exact: true,
        path:
          '/electrical-reviewer/request/occupancy/permit/details/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/electrical/for-review/PermitForOccupancyDetails'
          )
        )
      },

      {
        exact: true,
        path: '/electrical-reviewer/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  // plumbing routes
  {
    path: '/plumbing-reviewer',
    guard: PlumbingReviewerGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/plumbing-reviewer',
        component: () => <Redirect to="/plumbing-reviewer/reports/dashboard" />
      },
      {
        exact: true,
        path: '/plumbing-reviewer/reports/dashboard',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/plumbing')
        )
      },
      {
        exact: true,
        path: '/plumbing-reviewer/request/permit/requirements/:application_no',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/plumbing/requirements')
        )
      },
      {
        exact: true,
        path: '/plumbing-reviewer/reviewed/request/permit/approved-list',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/plumbing/reviewed')
        )
      },
      {
        exact: true,
        path:
          '/plumbing-reviewer/reviewed/request/occupancy/permit/approved-list',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/plumbing/reviewed/PermitForOccupancyReviewed'
          )
        )
      },

      {
        exact: true,
        path:
          '/plumbing-reviewer/electrical-permit/printable-from/:electrical_no',
        component: lazy(() => import('src/views/pages/PrintableElectricalForm'))
      },

      {
        exact: true,
        path: '/plumbing-reviewer/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },

      {
        exact: true,
        path:
          '/plumbing-reviewer/request/occupancy/permit/requirements/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/plumbing/requirements/PermitForOccupancyRequirements'
          )
        )
      },
      {
        exact: true,
        path:
          '/plumbing-reviewer/request/occupancy/permit/details/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/plumbing/for-review/PermitForOccupancyDetails'
          )
        )
      },

      {
        exact: true,
        path: '/plumbing-reviewer/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  // line-and-grade-reviewer routes
  {
    path: '/line-and-grade-reviewer',
    guard: LineAndGradeGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/line-and-grade-reviewer',
        component: () => (
          <Redirect to="/line-and-grade-reviewer/reports/dashboard" />
        )
      },
      {
        exact: true,
        path: '/line-and-grade-reviewer/reports/dashboard',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/line-and-grade')
        )
      },
      {
        exact: true,
        path:
          '/line-and-grade-reviewer/request/permit/requirements/:application_no',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/line-and-grade/requirements'
          )
        )
      },
      {
        exact: true,
        path: '/line-and-grade-reviewer/reviewed/request/permit/approved-list',
        component: lazy(() =>
          import('src/views/building-plans-reviewer/line-and-grade/reviewed')
        )
      },
      {
        exact: true,
        path:
          '/line-and-grade-reviewer/reviewed/request/occupancy/permit/approved-list',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/line-and-grade/reviewed/PermitForOccupancyReviewed'
          )
        )
      },

      {
        exact: true,
        path:
          '/line-and-grade-reviewer/electrical-permit/printable-from/:electrical_no',
        component: lazy(() => import('src/views/pages/PrintableElectricalForm'))
      },

      {
        exact: true,
        path: '/line-and-grade-reviewer/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },

      {
        exact: true,
        path:
          '/line-and-grade-reviewer/request/occupancy/permit/requirements/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/line-and-grade/requirements/PermitForOccupancyRequirements'
          )
        )
      },
      {
        exact: true,
        path:
          '/line-and-grade-reviewer/request/occupancy/permit/details/:application_no/:occupancy_id',
        component: lazy(() =>
          import(
            'src/views/building-plans-reviewer/line-and-grade/for-review/PermitForOccupancyDetails'
          )
        )
      },

      {
        exact: true,
        path: '/line-and-grade-reviewer/inquiries',
        component: lazy(() => import('src/views/pages/inquiries'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      // {
      //   exact: true,
      //   path: '/pricing',
      //   component: lazy(() => import('src/views/pages/PricingView'))
      // },

      {
        exact: true,
        path: '/guides',
        component: lazy(() => import('src/views/pages/HomeView/guides'))
      },

      {
        exact: true,
        path: '/home/terms-and-conditions',
        component: lazy(() => import('src/views/pages/TermsAndConditions'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
