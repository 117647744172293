import Print from '@material-ui/icons/Print';
import ListAltIcon from '@material-ui/icons/ShortText';

const EngineeringSubItem = () => {
  const links = {
    subheader: 'Engineering Submenu',
    owner: 'engineering',
    items: [
      // {
      //   title: 'Processed',
      //   icon: DoneAll,
      //   enabled: true,
      //   items: [
      //     {
      //       title: 'Building Permit',
      //       icon: Domain,
      //       href: '/engineering/reviewed/request/permit/approved-list',
      //       enabled: true
      //     }
      //   ]
      // },

      {
        title: 'For Print',
        icon: Print,
        enabled: true,
        items: [
          {
            title: 'Building Permits',
            icon: ListAltIcon,
            href: '/engineering/reviewed/request/permit/for-print',
            enabled: true
          },
          {
            title: 'Occupancy Permits',
            icon: ListAltIcon,
            href: '/engineering/reviewed/request/occupancy/permit/for-print',
            enabled: true
          },
          {
            title: 'Electrical Permits',
            icon: ListAltIcon,
            href: '/engineering/electrical-permit/completed',
            enabled: true
          }
        ]
      }
    ]
  };

  return links;
};

export default EngineeringSubItem;
