import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function MpdoGuard({ children }) {
  const account = useSelector(state => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'user') {
    return <Redirect to="/app" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'reviewer'
  ) {
    return <Redirect to="/reviewer" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'engineering'
  ) {
    return <Redirect to="/engineering" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'fire') {
    return <Redirect to="/fire" />;
  }

  if (account.user.roles.length > 0 && account.user.roles[0].name === 'admin') {
    return <Redirect to="/administrator" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'treasury'
  ) {
    return <Redirect to="/treasury" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'requirements-reviewer'
  ) {
    return <Redirect to="/requirements-reviewer" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'structural-reviewer'
  ) {
    return <Redirect to="/structural-reviewer" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'plumbing-reviewer'
  ) {
    return <Redirect to="/plumbing-reviewer" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'electrical-reviewer'
  ) {
    return <Redirect to="/electrical-reviewer" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'architectural-reviewer'
  ) {
    return <Redirect to="/architectural-reviewer" />;
  }

  if (
    account.user.roles.length > 0 &&
    account.user.roles[0].name === 'line-and-grade-reviewer'
  ) {
    return <Redirect to="/line-and-grade-reviewer" />;
  }

  return children;
}

MpdoGuard.propTypes = {
  children: PropTypes.any
};

export default MpdoGuard;
