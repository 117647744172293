// import DoneAll from '@material-ui/icons/DoneAll';
// import DvrIcon from '@material-ui/icons/Dvr';
import ListAltIcon from '@material-ui/icons/ShortText';

const RequirementsReviewerSubItem = () => {
  const links = {
    subheader: 'Requirements Reviewer Submenu',
    owner: 'requirements-reviewer',
    items: [
      // {
      //   title: 'For Engineering',
      //   icon: DvrIcon,
      //   href: '/requirements-reviewer/reviewed/request/for-engineering',
      //   enabled: true
      // },

      {
        title: 'Processed Permits',
        icon: ListAltIcon,
        href: '/requirements-reviewer/reviewed/request/permit/approved-list',
        enabled: true
      },

      {
        title: 'Processed Permits For Occupancy',
        icon: ListAltIcon,
        href:
          '/requirements-reviewer/reviewed/request/occupancy/permit/approved-list',
        enabled: true
      }

      // {
      //   title: 'Processed Permits For Electrical',
      //   icon: ListAltIcon,
      //   href: '/requirements-reviewer/electrical-permit/request/completed-list',
      //   enabled: true
      // }
    ]
  };

  return links;
};

export default RequirementsReviewerSubItem;
