/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const socketBaseURL = process.env.REACT_APP_SOCKETBASEURL;
export const offices = [
  { role_id: 6, name: 'ENGINEERING' },
  { role_id: 8, name: 'FIRE' },
  { role_id: 9, name: 'MPDO' },
  { role_id: 12, name: 'TREASURY' },
  { role_id: 7, name: 'REVIEWER' },
  { role_id: 13, name: 'REQUIREMENTS REVIEWER' },
  { role_id: 14, name: 'ELECTRICAL REVIEWER' },
  { role_id: 15, name: 'ARCHITECTURAL REVIEWER' },
  { role_id: 16, name: 'STRUCTURAL REVIEWER' },
  { role_id: 17, name: 'PLUMBING REVIEWER' }
];
