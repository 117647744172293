import DoneAll from '@material-ui/icons/DoneAll';

const TreasurySubItem = () => {
  const links = {
    subheader: 'Treasury Submenu',
    owner: 'treasury',
    items: [
      {
        title: 'Paid Bldg Permit',
        icon: DoneAll,
        href: '/treasury/approved/request/permit/paid-list',
        enabled: true
      },
      {
        title: 'Paid Occupancy Permit',
        icon: DoneAll,
        href: '/treasury/approved/request/occupancy/permit/paid-list',
        enabled: true
      },

      {
        title: 'Paid Electrical Permit',
        icon: DoneAll,
        href: '/treasury/electrical-permit/completed',
        enabled: true
      }
    ]
  };

  return links;
};

export default TreasurySubItem;
