import DoneAll from '@material-ui/icons/DoneAll';

const MpdoSubItem = () => {
  const links = {
    subheader: 'MPDO Submenu',
    owner: 'mpdo',
    items: [
      {
        title: 'Approved Permits',
        icon: DoneAll,
        href: '/mpdo/approved/request/permit/approved-list',
        enabled: true
      }
    ]
  };

  return links;
};

export default MpdoSubItem;
