import {
  Business as BusinessIcon,
  FeaturedPlayList,
  Payment,
  AccountBalance
} from '@material-ui/icons';
import ListAltIcon from '@material-ui/icons/ShortText';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';

const UsersSubItems = () => {
  const links = {
    // subheader: 'Sub Menu',
    owner: 'user',
    items: []

    // [
    //   {
    //     title: 'Building Permit',
    //     icon: BusinessIcon,
    //     href: '/app/building-permit/new',
    //     enabled: true
    //   },
    //   {
    //     title: 'Permit List',
    //     icon: FeaturedPlayList,
    //     enabled: true,
    //     items: [
    //       {
    //         title: 'List',
    //         href: '/app/users/request/permit-list',
    //         enabled: true,
    //         icon: ListAltIcon
    //       },
    //       {
    //         title: 'Draft',
    //         href: '/app/building-permit/draft',
    //         enabled: true,
    //         icon: ListAltIcon
    //       }
    //     ]
    //   },
    //   {
    //     title: 'Permit For Payment',
    //     icon: Payment,
    //     href: '/app/users/request/permit-forpayment',
    //     enabled: true
    //   },
    //   {
    //     title: 'Occupancy Permit',
    //     icon: AccountBalance,
    //     enabled: true,
    //     items: [
    //       {
    //         title: 'New',
    //         href: '/app/users/request/permit-foroccupancy',
    //         enabled: true,
    //         icon: ListAltIcon
    //       },
    //       {
    //         title: 'List',
    //         href: '/app/users/request/permit-foroccupancy/list',
    //         enabled: true,
    //         icon: ListAltIcon
    //       }
    //     ]
    //   },

    //   {
    //     title: 'Electrical Permit',
    //     icon: DeviceHubIcon,
    //     items: [
    //       {
    //         title: 'List',
    //         icon: ListAltIcon,
    //         href: '/app/users/electrical-permit/request-list',
    //         enabled: true
    //       }
    //     ]
    //   }
    // ]
  };

  return links;
};

export default UsersSubItems;
