import { ChevronsRight, Layout, List, Users } from 'react-feather';

const AdministratorSubItem = () => {
  const links = {
    subheader: 'Administrator Submenu',
    owner: 'admin',
    items: [
      {
        title: 'Reports',
        icon: List,
        enabled: true,
        items: [
          {
            title: 'Payments',
            href: '/administrator/reports/payments',
            enabled: true,
            icon: ChevronsRight
          }
        ]
      },
      {
        title: 'Users',
        icon: Users,
        enabled: true,
        items: [
          {
            title: 'User',
            href: '/administrator/users',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'Roles',
            href: '/administrator/users/roles',
            enabled: true,
            icon: ChevronsRight
          }
        ]
      },

      {
        title: 'Building Permit',
        icon: Layout,
        enabled: true,
        items: [
          {
            title: 'Municipality',
            href: '/administrator/building/permit/municipality',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'Barangay',
            href: '/administrator/building/permit/barangay',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'Application Type',
            href: '/administrator/building/permit/type',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'Scope of Work',
            href: '/administrator/building/permit/scope',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'Form of Ownership',
            href: '/administrator/building/permit/ownership',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'Group Use',
            href: '/administrator/building/permit/group-use',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'Character of Occupancy',
            href: '/administrator/building/permit/occupancy-character',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'Application Status',
            href: '/administrator/building/permit/status',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'Fees Type',
            href: '/administrator/building/permit/fees-type',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'Fees Account',
            href: '/administrator/building/permit/fees-account',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'Grouping of Requirements',
            href: '/administrator/building/permit/requirements/grouping',
            enabled: true,
            icon: ChevronsRight
          },
          {
            title: 'List of Requirements',
            href: '/administrator/building/permit/requirements/list',
            enabled: true,
            icon: ChevronsRight
          }
        ]
      }
    ]
  };

  return links;
};

export default AdministratorSubItem;
