import DoneAll from '@material-ui/icons/DoneAll';

const FireSubItem = () => {
  const links = {
    subheader: 'Fire Submenu',
    owner: 'fire',
    items: [
      {
        title: 'Processed Permits',
        icon: DoneAll,
        href: '/fire/approved/request/permit/approved-list',
        enabled: true
      },

      {
        title: 'Processed Permits For Occupancy',
        icon: DoneAll,
        href: '/fire/reviewed/request/occupancy/permit/approved-list',
        enabled: true
      }
    ]
  };

  return links;
};

export default FireSubItem;
