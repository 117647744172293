import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Bell as BellIcon,
  MessageCircle as MessageIcon,
  ExternalLink
} from 'react-feather';
import { SocketContext } from 'src/context/SocketContext';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function Notifications() {
  const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { socket } = useContext(SocketContext);
  const { user } = useSelector(state => state.account);
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const user_type = user?.roles?.[0]?.name;
  const userId = user_type === 'user' ? user?.id : user?.roles?.[0]?.id;

  const getNotifications = async () => {
    const response = await axios.get('/api/notifications', {
      params: {
        userId: userId
      }
    });
    setNotifications(response.data);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetNotificationAsRead = e => {
    e.preventDefault();

    socket.emit('notifications-setas-read', {
      userId: userId
    });
    setNotifications([]);
  };

  useEffect(() => {
    socket.emit('join', {
      userId: userId
    });

    socket.on('received-notification', data => {
      setNotifications(prev => prev.concat(data));
    });

    getNotifications();

    // eslint-disable-next-line
  }, [dispatch]);

  const roles = user?.roles?.[0].name;
  const navigate =
    roles === 'user' ? '/app/users/inquiries' : `/${roles}/inquiries`;

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <Badge badgeContent={notifications.length} color="error">
            <SvgIcon>
              <BellIcon />
            </SvgIcon>{' '}
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box
          p={2}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box>
            <Typography variant="h5" color="textPrimary">
              Notifications
            </Typography>
          </Box>

          <Box>
            <IconButton onClick={() => history.push(navigate)}>
              <ExternalLink />
            </IconButton>
          </Box>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List className={classes.list} disablePadding>
              {notifications.map(notification => {
                let extras =
                  notification.type?.trim() === 'inquiry-message'
                    ? JSON.parse(notification.extras)
                    : null;

                let inquiry_id = extras?.inquiry_id ?? '';
                let url = `/${
                  user_type === 'user' ? 'app/users' : 'requirements-reviewer'
                }/inquiries?inquiry_id=${inquiry_id}`;

                return (
                  <ListItem
                    className={classes.listItem}
                    component={RouterLink}
                    divider
                    key={notification.id}
                    to={url}
                    button
                  >
                    <ListItemIcon>
                      <MessageIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: 'textPrimary'
                      }}
                      secondary={notification.message}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box p={1} display="flex" justifyContent="center">
              <Button
                component={RouterLink}
                size="small"
                to="#"
                onClick={e => handleSetNotificationAsRead(e)}
              >
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
}

export default Notifications;
